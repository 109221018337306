<template>
    <div class="page-content header-clear-small">
          <div style="height:100px">
        </div>
		<div class="card card-style contact-form" v-if="success">
            <div class="shadow-l rounded-m bg-gradient-green1 mr-n1 ml-n1 mb-n1 ">
                <h1 class="color-white text-center pt-4"><i class="fa fa-check-circle fa-3x shadow-s scale-box rounded-circle"></i></h1>
                <h2 class="color-white bold text-center pt-3">Message Envoyé</h2>
                <p class="color-white pb-4 text-center mt-n2 mb-0">Nous vous répondrons dans les plus brefs délais.</p>
            </div>
        </div>
         <div class="card card-style  contact-form" v-if="failure">
            <div class="shadow-l rounded-m bg-gradient-red1 mr-n1 ml-n1 mb-n1 ">
                <h1 class="color-white text-center pt-4"><i class="fa fa-times fa-3x shadow-s scale-box rounded-circle"></i></h1>
                <h2 class="color-white bold text-center pt-3">Erreur</h2>
                <p class="color-white pb-4 text-center mt-n2 mb-0">Veuillez réessayer ultérieurement.</p>
            </div>
        </div>       
        <div class="card card-style contact-form" v-if="!success && !failure">
            <div class="content">
			
			<h4 class="text-uppercase font-600 mb-2 font-14">Contactez-nous</h4>
			<div class="divider bg-orange-dark-sdw mb-3"></div>
			<p class="mt-n1 mb-40">Collectivité, notre appli vous intéresse ?</p>
                <form class="contactForm">
                    <fieldset>
                        <div class="form-field form-name">
                         
							
							<input type="text" autocomplete="chrome-off" class="contactField round-small requiredField" :class="nameError ? 'border-red-dark':''" v-model="contactName" placeholder="Votre Nom et prénom">
							
                        </div>
                        <div class="form-field form-email">
                           
                            <input type="text" autocomplete="chrome-off" class="contactField round-small requiredField" :class="(emailError || emailValidError) ? 'border-red-dark':''" v-model="contactEmail" placeholder="Votre Email">
                        </div>
                        <div class="form-field form-email">
                            
                            <input type="text" autocomplete="chrome-off" class="contactField round-small" v-model="contactPhone" placeholder="Votre numéro de téléphone">
                        </div>						
                        <div class="form-field form-name">
							
							<input type="text" autocomplete="chrome-off" class="contactField round-small requiredField" :class="communeError ? 'border-red-dark':''" v-model="contactCommune" placeholder="Votre commune ou EPCI">
							
                        </div>						
                        <div class="form-field form-text">					
							<textarea name="contactMessageTextarea" v-model="contactMessage" style="height:80px" class="contactTextarea round-small requiredField" placeholder="Votre Message" :class="messageError ? 'border-red-dark':''"></textarea>
                        </div>
                        <div class="form-button text-center">
                            <a href="#" @click.prevent="sendMessage" class="btn btn-full btn-m shadow-l rounded-s font-500 bg-orange-dark-sdw mt-4 mb-4 color-white nice-btn">Envoyer</a>
                        </div>
                    </fieldset>	
                </form>		
        <div class="content" v-if="nameError">
            <p class="text-center text-uppercase p-2 color-red font-700 ">Veuillez saisir votre nom et prénom!</p>
        </div>
        <div class="content" v-if="emailError">
            <p class="text-center text-uppercase p-2 color-red font-700">Veuillez saisir votre email!</p>
        </div>
        <div class="content" v-if="emailValidError">
            <p class="text-center text-uppercase p-2 color-red font-700">Votre email doit être valide!</p>
        </div>
        <div class="content" v-if="messageError">
            <p class="text-center text-uppercase p-2 color-red font-700">Veuillez saisir votre message!</p>
        </div>

		<div class="content" v-if="communeError">
            <p class="text-center text-uppercase p-2 color-red font-700">Veuillez saisir votre commune ou EPCI!</p>
        </div>				
            </div>
        </div>
    </div>
    <!-- End of Page Content--> 
</template>
<script>
import AppConstants from '@/config'
export default {
  name: 'Contact',
  data() {
    return{
	loading: false,
	contactName:'', 
	contactCommune:'', 
	contactMessage:'', 
	contactEmail:'',
	contactPhone:'',
	nameError : false,
	emailError: false,
	phoneError: false,
	emailValidError: false,
	messageError: false,
	communeError: false,
	success: false,
	failure: false
	}
  },
  components: {
  },
  props: {	
  },  
  created() {
     
  },  
  mounted() {
  },   
  methods: {
	getConst(key){
		const Numbers = {
				API_URL : AppConstants.API_URL,
				API_TOKEN : AppConstants.API_TOKEN,
				API_ID: AppConstants.API_ID,
		};
        return Numbers[key];	
	},
	initForm() {
		this.nameError = false
		this.emailError =  false
		this.emailValidError = false
		this.phoneError = false
		this.messageError = false
		this.communeError = false
		this.success = false
		this.failure = false
	},
	validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
	},
	sendMessage() {
				this.initForm()
				if (this.contactName.length == 0) this.nameError = true
				else if (this.contactEmail.length == 0) this.emailError = true
				else if (!this.validateEmail(this.contactEmail)) this.emailValidError = true
				else if (this.contactCommune.length == 0) this.communeError = true
				else if (this.contactMessage.length == 0) this.messageError = true
				
				if (!this.nameError && !this.emailError && !this.emailValidError && !this.messageError && !this.communeError) {
				var token = this.getConst('API_TOKEN')
				var id = this.getConst('API_ID')
				var apiUrl = this.getConst('API_URL')+"anonymous-contact"				
				if(id && token) {
                    var apiData = { 
					"name": this.contactName,
					"message": this.contactMessage,
					"email": this.contactEmail,
					"phone": this.contactPhone,
					"commune": this.contactCommune,
					"token" : token,
					"appid": id
					}
					let axios = require('axios');
					axios.post(apiUrl, apiData)
					.then(response => {
					if (response.data.message == 'OK') {
						this.contactName = ''
						this.contactCommune = '' 
						this.contactMessage = '' 
						this.contactEmail = ''
						this.contactPhone = ''
						this.success = true
					}
				})
				.catch(error => {
				console.log(error.message);
				this.failure = true
				})	
				} 
				}
	},	
  }
}
</script>
<style>
.border-red-dark {
    border-color: #DA4453 !important;
}
</style>

